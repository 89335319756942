<template>
  <v-container class="text-center">
    <h2 class="pb-8" style="padding-top: 28vh">User</h2>
    <v-simple-table class="rounded-xl pa-4 pb-9">
      <template v-slot:default>
        <thead>
        <tr>
          <th class=" text-h6">
            Name
          </th>
          <th class=" text-h6">
            Name
          </th>
        </tr>
        </thead>

        <tbody
        >
        <tr
            v-for="user in users"
            :key="user.id"
            class="text-start"
        >
          <td>
            <span class="subtitle-1 ps-0 ps-md-6">{{ user.$id }}</span>
          </td>
          <td><span class="subtitle-1 ps-0 ps-md-6">{{ user.firstname }} {{ user.lastname }}</span></td>
          <td><span class="subtitle-1 ps-0 ps-md-6">{{ user.id }}</span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row
    class="pt-8">
      <v-col>
        <h3>Allen eine Benachrichtigung erstellen</h3>
        {{user}}
        <v-form>
          <v-text-field v-model="icon" label="icon">

          </v-text-field>
          <v-text-field v-model="title" label="Title">

          </v-text-field>
          <v-text-field v-model="message" label="Nachricht">

          </v-text-field>
          <v-btn @click="createNotification" v-if="icon&& title&& message" class="red">Erstellen</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "CreateAccount",
  data(){
    return{
      title:'',
      message:'',
      icon:''
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch('admin/getUsers').catch((err) => {
      this.message = {
        show: true,
        text: err,
        color: "error",
      }
      setTimeout(() => {
        this.message.show = false
      }, 5000);
    })
  },
  methods:{
    createNotification(){
      this.$store.dispatch('admin/createMessageForAll', {
        uid: this.user.id,
        users:this.users,
        message:this.message,
        icon:this.icon,
        title:this.title})
    }
  },
  computed: {
    ...mapGetters('admin', {
      users: 'users',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
}
</script>
